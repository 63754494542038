.home-page-wrapper{
    padding-top: 80px;
    width: 60vw;
}

.about-logos{
    padding-top: 1px;
    display: flex;
    flex-direction: row;
    a{
        opacity: .8;
        width: 27px;
        padding-right: 10px;
        &:hover{
            opacity: 1;
        }
    }
}
  
   @media only screen and (max-width: 550px) {
    .home-page-wrapper{
        margin-top: 60px;
        width: 100vw;
        padding: 20px;
    }
   }