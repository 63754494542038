@import "../../StyleVariables.scss";

@font-face {
  font-family: 'LetterGothicStd';
  font-style: normal;
  font-weight: normal;
  src: local('LetterGothicStd'), url(../../assets/LetterGothicStd.otf) format('opentype');
}

.small-nav-collapsible{
  margin-top: 12px;
}

.large-nav-collapsible{
  margin-top: 7px;
}

.small-nav-collapsible-inner{
  margin: auto;
  display: table;
  font-size: 15px;
}

.Collapsible__trigger{
  font-family: "LetterGothicStd";
  -webkit-text-stroke-width: .3px;
  -webkit-text-stroke-color: black;
  font-size: 13px;
  &:hover{
    -webkit-text-stroke-width: .4px;
    -webkit-text-stroke-color: black;
    cursor: pointer;
  }
}

.small-nav-collapsible-inner{
  margin-top: 12px;
  margin: auto;
  display: table;
  font-size: 15px;
}

.Collapsible__contentInner{
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  a{
    padding: 10px;
    &:hover{
      -webkit-text-stroke-width: .5px;
      -webkit-text-stroke-color: black;
    }
    }
}

.small-nav-collapsible-inner-content{
  margin-top: 7px;
  padding: 0;
}

.logo-container{
  width: 150px;
}

.logo-container-small{
  position: fixed;
  z-index: 500;
  top: 25px;
  left: 25px;
  width: 150px;
}

.large-nav-item{
  margin-top: 7px;
}

.small-nav-item{
  margin-top: 12px;
  display: flex;
  align-content: center;
}

.not-selected{
  color: black;
  font-size: 13px;
  font-family: "LetterGothicStd";
  -webkit-text-stroke-width: .3px;
  -webkit-text-stroke-color: black;
}

.not-selected-small{
  width: 100%;
  color: black;
  font-size: 15px;
  font-family: "LetterGothicStd";
  -webkit-text-stroke-width: .3px;
  -webkit-text-stroke-color: black;
  text-align: center;
}

.selected{
  font-family: "LetterGothicStd";
  -webkit-text-stroke-width: .6px;
  -webkit-text-stroke-color: black;
  text-decoration: line-through;
}

.selected-small{
  font-family: "LetterGothicStd";
  -webkit-text-stroke-width: .6px;
  -webkit-text-stroke-color: black;
  text-decoration: line-through;
}

.copyright-wrapper{
 font-family: 'Courier New', Courier, monospace;
 font-size: 10pt;
}

.large-nav-links{
  margin-top: 50px;
}

.small-nav-links{
  width: 60vw;
  min-width: 250px;
}

.large-nav-wrapper{
  padding-top: 20px;
  padding-left: 35px;
  width: 20vw;
}

.checkbox-wrapper {
  width: 30px;
  height: 30px;
  position: fixed;
  z-index: 300;
  top: 25px;
  right: 25px;
  transform: scale(.7);
}

.checkbox-wrapper {
  input {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 250;
    cursor: pointer;
    opacity: 0;
    -webkit-touch-callout: none;
  }
  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: absolute;
    z-index: 205;
    background: rgb(19, 19, 19);
    border-radius: 3px;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
    opacity: 0.9;
  }
}
  
.checkbox-wrapper:hover span {
  opacity: 0.9;
}
  
.checkbox-wrapper span:first-child {
  transform-origin: 0% 0%;
}
  
.checkbox-wrapper span:nth-child(2) {
  transform: translate(0px, 10px);
}
  
.checkbox-wrapper span:nth-child(3) {
  transform: translate(0px, 20px);
}
  
.checkbox-wrapper input:checked ~ span {
  position: absolute;
  opacity: 1;
  transform: rotate(45deg) translate(0, 0);
  background: rgb(19, 19, 19);
}
  
.checkbox-wrapper input:checked ~ span:nth-last-child(3) {
  position: absolute;
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
  
.checkbox-wrapper input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(-14px, 11px);
}

@mixin menu {
  position: fixed;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  top: 0;
  left: 0;
  height: 100vh;
  margin: 0;
  transform-origin: 0% 0%;
  width: 100vw;
}

@mixin mask {
  position: fixed;
  z-index: 103;
  display: block;
  top: 0;
  right: 0;
  height: 100vh;
  margin: 0;
  padding-top: 25vh;
  padding-left: 10vw;
  transform-origin: 0% 0%;
  @include extrasmall {
    width: 10vw;
  }
  @include small {
    width: 20vw;
  }
  @include medium {
  width: 50vw;
  }
  @include large {
    width: 65vw;
    }
}

#menu-unchecked {
  @include menu;
  background: #ac9c87;
  transform-origin: 0% 0%;
  transform: translate(200%, 0);
  transition: transform 0.7s cubic-bezier(0.77, 0.2, 0.05, 1);
  -webkit-box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.65);
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.65);
}

#menu-checked {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include menu;
  background: #ac9c87;
  transform-origin: 0% 0%;
  transition: transform 0.7s cubic-bezier(0.77, 0.2, 0.05, 1);
  -webkit-box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.65);
  box-shadow: 4px 3px 5px 0px rgba(0, 0, 0, 0.65);
}

.screen-mask{
  z-index: 101;
  background-color: transparent;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
}

.screen-mask-none{
  display: none;
}

.nav-item {

  position: relative;
  margin-bottom: 5vh;
}

.normal {
  font-size: 1.25rem;
  font-weight: bolder;
  color: #fff;
  text-decoration: none;
  opacity: .6;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
}

.active {
  opacity: 1;
  margin-bottom: 5vh;
  font-weight: 900;
}

a {
  text-decoration: none;
}

.postings-text {
  font-size: 1.25rem;
  font-weight: bolder;
  color: rgb(26, 26, 26);
  text-decoration: none;
  opacity: .6;
  transition: 0.5s;
  display: flex;
  &:hover {
    opacity: 1;
  }
  cursor: pointer;
}

.postings-asset {
  font-size: 1.25rem;
  font-weight: bolder;
  color: rgb(26, 26, 26);
  text-decoration: none;
  opacity: .6;
  transition: 0.5s;
  display: flex;
  &:hover {
    opacity: 1;
  }
  cursor: pointer;
  margin-left: 10px;

}

.postings-container {
  margin-bottom: 5vh;
}

.postings-list {
  color: rgb(26, 26, 26);
}

.postings-heading {
  display: flex;
}

.pending-normal {
  font-size: 1rem;
  font-weight: bolder;
  color: rgb(26, 26, 26);
  text-decoration: none;
  opacity: .6;
  transition: 0.5s;
  &:hover {
    opacity: 1;
  }
}

.pending-active {
  opacity: 1;
  margin-bottom: 1vh;
  font-weight: 900;
}


.pending-item {
  margin-bottom: 2vh;
  margin-top: 2vh;
}


.postings-list-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
  margin-left: 40px;
}

.item-container {
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.icon{
  width: 27px;
  height: 27px;
  margin: 3px;
}

.external-icons{
  position: absolute;
  display: flex;
  bottom: 10px;
  right: 10px;
}

 @media only screen and (min-width: 550px) {
  .nav-small{
    display: none;
  }
 }

 @media only screen and (max-width: 550px) {
  .large-nav-wrapper{
    display: none;
  }
 }