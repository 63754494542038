.film-wrapper {
  position: relative;
  margin-top: 95px;
}

.preview-image {
  position: relative;
  img {
    width: 60vw;
  }
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}

.video-wrapper {
  width: 60vw;
  height: 33.75vw;
}

.film-info {
  width: 60vw;
  padding-top: 30px;
}

.film-title {
  font-family: "LetterGothicStd";
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
  font-size: 15px;
}

.film-date-year {
  padding-top: 20px;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
}

.film-description {
  padding-top: 10px;
  padding-bottom: 70px;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  h4 {
    font-family: "LetterGothicStd";
    -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: black;
    font-size: 14px;
    padding-top: 30px;
    padding-bottom: 10px;
  }
}

.invisible {
  opacity: 0;
}

.visible {
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.play {
  opacity: 0.6;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 40px;
  }
  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}

@media only screen and (min-width: 550px) {
  .nav-small{
    display: none;
  }
 }

 @media only screen and (max-width: 550px) {
  .film-wrapper {
    width: 100vw;
    position: relative;
    margin-top: 95px;
    padding: 20px;
  }
  
  .preview-image {
    position: relative;
    img {
      width: 100%;
    }
  }
  
  .video-wrapper {
    width: 100%;
    height: 300px;
  }
  
  .film-info {
    width: auto;
    padding-top: 30px;
  }
 }